<template>
    <div class="box">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover">
        <div class="banner">
            <div class="logo">
                <img src="~@/assets/images/postShare/logo.png" alt="">
            </div>
            <div class="text">
                恭喜您已领取
            </div>
            <div class="share">
                <img src="~@/assets/images/postShare/word.png" alt="">
                <button @click="seeCoupon">查看优惠券</button>
            </div>
        </div>
        <div class="body">
            <div class="title">
                <p>保存图片并发送</p>
                <p>多种方式 方便您的权益使用</p>
            </div>
            <div ref="art" @click="seePhoto">
               <img v-if="isShowPic" src="~@/assets/images/postShare/post.jpg" alt="">
               <img v-else :src="shareImage" alt="">
            </div>

        </div>
        <van-popup v-model="show" position="top">
            <div class="imag">
                <img class="tip" src="~@/assets/images/postShare/tip.png" alt="">
            </div>
            <div class="btn">
                <button @click="konw">我知道了</button>
            </div>
        </van-popup>
        <div class="look" v-if="showPopule">
            <van-loading size="80px" color="#0094ff" vertical>加载中...</van-loading>
        </div>
        <div class="saveBtn">
                <button @click="forward">
                    <img src="~@/assets/images/postShare/share.png" alt="">
                    转发朋友圈
                </button>
                <button @click="seePhoto">
                    <img src="~@/assets/images/postShare/save.png" alt="">
                    截图保存
                </button>
            </div>
    </div>
</template>
<script>
import { ImagePreview } from 'vant';
import distubritionTwoApi from "@/untils/distubritionTwo.js";
import isweixin from "@/untils/isweixin.js";
import { getWxConfig } from "@/untils/distubritionIndex.js";
var wx = require('weixin-js-sdk') || window['wx'];
import Vue from 'vue';
import { Loading } from 'vant';
Vue.use(Loading);
export default {
    data() {
        return {
            show: false,//转发朋友圈按钮弹出层
            openId: '',
            sp: '',
            phone: '',
            // shareImage: "~@/assets/images/postShare/post.png",
            shareImage: "../../assets/images/postShare/post.png",
            showPopule:false,
            isShowPic:true,
            titleInfo:{},
            jsSDK:{},
        }
    },
    mounted() {
        let _that = this
        var url = window.location.href;
        if (url.indexOf('?') > -1) {
            var cs = url.split('?')[1];
            var cs_arr = cs.split('&');
            var cs = {};
            for (var i = 0; i < cs_arr.length; i++) {         //遍历数组，拿到json对象
                cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1]
            }
            if (cs.p_openid == undefined) {
                if (cs.phone) {
                    this.phone = cs.phone
                } else {
                    this.phone = ''
                }
                this.getUserInfoOpenId(url)
            } else {
                this.openId = cs.p_openid
                this.sp = cs.sp
                this.phone = cs.phone

            }
        } else {
            this.getUserInfoOpenId(url)
        }
        let ShareUrl = "https://iot.yuna6.com/bigFlowPage?p_openid="+this.openId + '&sp='+this.sp+'&from=3';
           
        this.getPostShare(encodeURIComponent(ShareUrl))

        setTimeout(() => {
            wx.ready(() => {
                wx.updateAppMessageShareData({
                    title: _that.titleInfo.title, // 分享标题
                    desc: _that.titleInfo.info, // 分享描述
                    link: _that.titleInfo.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: _that.titleInfo.image, // 分享图标
                    success: function (res) {
                        console.log(res,'设置成功')
                        // 设置成功
                    }
                })
                wx.updateTimelineShareData({
                    title: _that.titleInfo.title, // 分享标题
                    link: _that.titleInfo.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: _that.titleInfo.image, // 分享图标
                    success: function () {
                        // 设置成功
                        console.log(res,'设置成功')
                    }
                })
            });
        }, 1000)



    },
    created() {
        let _that = this
        var u = location.href.split("#")[0]

        let data = {
            shareurl: u,
            url:u
        }
        getWxConfig({ data }).then(res => {
            _that.jsSDK = res.data.data.data
            _that.titleInfo = res.data.data.contentArr
            wx.config({
                debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
                appId: _that.jsSDK.appid, // 必填，公众号的唯一标识
                timestamp: _that.jsSDK.timestamp, // 必填，生成签名的时间戳
                nonceStr: _that.jsSDK.nonceStr, // 必填，生成签名的随机串
                signature: _that.jsSDK.signature,// 必填，签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的 JS 接口列表
            });

        })


    },
    methods: {
        // 获取分享海报
        getPostShare(ShareUrl) {
            this.showPopule = true
            let data = { url: ShareUrl }
            let _that = this;
             distubritionTwoApi.getPostShare({ data }).then((res) => {
                _that.shareImage = res.data.data.data;
                _that.showPopule = false
                _that.isShowPic = false
            })
        },
        getUserInfoOpenId(url) {
            // window.location.href = 'https://connect.yuna6.com/getUserInfo?phone=18239921595&url=https://iots.yunruiot.com/postShare'
            window.location.href = 'https://connect.yuna6.com/getUserInfo?phone='+ this.phone+'&url=' + url

        },
        // 转发朋友圈按钮
        forward() {
            this.show = true;
        },
        // 我知道了
        konw() {
            this.show = false;
        },
        // 预览图片
        seePhoto() {
            // 预览图片的方法
            this.$nextTick(function () {
                // 1.获取所有图片
                const imgs = this.$refs.art.querySelectorAll('img')
                ImagePreview([imgs[0].src]);
            })
        },
        // 查看优惠券按钮
        seeCoupon() {
            this.$router.push({
                path:'/coupon',
                query:{
                    phone:this.phone,
                    openid:this.openId
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
.box {
    background-color: #000000;
    overflow: hidden;

    .banner {
        overflow: hidden;
        position: relative;
        background-image: url('~@/assets/images/postShare/Banner1.png');
        background-size: 100%;
        background-repeat: no-repeat;

        .logo {
            margin: 24px 25px;

            img {
                width: 77px;
            }
        }

        .text {
            margin-top: 77px;
            color: #fff;
            font-size: 20px;
            margin-left: 21px;
        }

        .share {
            margin-top: 18px;
            display: flex;
            // justify-content: space-around;
            justify-content: space-between;

            img {
                height: 44px;
                margin-left: 21px;
            }

            button {
                width: 131px;
                height: 40px;
                border: 2px solid #F3E2BA;
                border-radius: 36px;
                background-color: transparent;
                font-weight: 400;
                color: #FCF8EE;
                font-size: 16px;
                margin-right: 21px;
            }
        }
    }

    .body {
        width: 95%;
        margin: 10px auto;
        background-image: url('~@/assets/images/postShare/bg1.png');
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        overflow: hidden;
        padding-bottom: constant(safe-area-inset-bottom);
            /* 兼容 iOS < 11.2 */
            padding-bottom: env(safe-area-inset-bottom);
            /* 兼容 iOS >= 11.2 */
        // position: relative;
        margin-bottom: 20px;
        .title {
            color: #C1B584;
            text-align: center;

            p:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
                background: linear-gradient(0deg, #CCCB86 0%, #F5F6E8 99.6337890625%);
                -webkit-background-clip: text;
                color: transparent;
                margin-top: 19px;
                letter-spacing: 3px;
            }

            p:nth-child(2) {
                font-size: 17px;
                margin-top: 9px;
                margin-bottom: 28px;
            }
        }

        img {
            width: 260px;
            border-radius: 10px;
        }

        
    }

    .van-popup {
        background-color: transparent;

        .imag {
            text-align: right;

            img {
                width: 280px;
                margin-right: 20px;

            }
        }

        .btn {
            color: #FEF1D8;
            text-align: center;
            margin-top: 100px;
            font-size: 20px;

            button {
                background-color: transparent;
                border: 1px solid #fff;
                padding: 10px 20px;
                border-radius: 12px;
            }
        }

    }
    .look{
        width: 100vw;
        height: 100vh;
        background-color: #1C2221;
        opacity: 0.9;
        position: fixed;
        top: 0;
        left: 0;
        text-align: center;
        padding-top: 80%;

    }
    .saveBtn {
            // position: absolute;

            // bottom: 0;
            margin-top: 10px;
            padding-bottom: 10px;
            display: flex;
            justify-items: center;
            text-align: center;
            width: 100%;
            position: fixed;
            bottom: 0;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 167px;
                height: 38px;
                background: linear-gradient(90deg, #E4AC7D 0%, #F3E2BA 100%);
                border-radius: 18px;
                margin: 0 auto;
                font-weight: 500;
                color: #2E1B10;
                font-size: 16px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 7px;
                }
            }

            button:nth-child(1) {
                background: linear-gradient(90deg, #EBC59A 0%, #FFF4DB 100%);
            }

            button:nth-child(2) {
                background: linear-gradient(90deg, #E4AC7D 0%, #F3E2BA 100%);
            }
        }
}
</style>